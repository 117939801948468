import { ref, Ref } from "vue";
import { merge } from "lodash";
import {
  Field,
  FieldDescriptor,
  Form,
  FormConfig,
  FormSubmitFn,
  FormSubmitServerParams,
  FormValues,
  SubmitSelection
} from "@/types";
import store from "@/store";
import { getFieldConfig, getFieldObject } from "@/config/form";
import { expandDescriptorToIndexes, toDescriptorObject } from "@/utils/indexed-field";

export interface FormHandlerParams {
  descriptors?: FieldDescriptor[];
}

export interface HandlerSubmitSelection {
  handler: FormHandler;
  submitSelection: SubmitSelection;
}

export abstract class FormHandler {
  form: Ref<Form>;
  descriptors: FieldDescriptor[];

  constructor(form: Ref<Form>, params: FormHandlerParams = {}) {
    this.form = form;
    this.descriptors = params.descriptors ?? [];
  }

  getFormConfig(): Partial<FormConfig> {
    return {};
  }

  // eslint-disable-next-line
  populateForm(force = false): void {}

  // eslint-disable-next-line
  afterFirstPopulate(): void {}

  getSubmitSelection(): SubmitSelection {
    const form = this.form.value;

    const descriptors = this.descriptors.flatMap(descriptor => {
      const descriptorObj = toDescriptorObject(descriptor);
      const fieldConfig = getFieldConfig(this.form.value.config, descriptorObj.name);
      return expandDescriptorToIndexes(form, descriptor, fieldConfig.dimensions);
    });
    return { descriptors };
  }

  // eslint-disable-next-line
  beforeSubmit(submitSelection: SubmitSelection): void {}

  // eslint-disable-next-line
  getValuesToSubmit(submitSelection: SubmitSelection): FormValues {
    return {};
  }

  async submit(
    // eslint-disable-next-line
    values: FormValues,
    // eslint-disable-next-line
    submitSelection: SubmitSelection,
    // eslint-disable-next-line
    serverParams: FormSubmitServerParams
  ): Promise<boolean> {
    throw Error("submit not implemented");
  }

  // eslint-disable-next-line
  afterSubmit(success: boolean, submitSelection: SubmitSelection): void {}
}

interface FormHandlerConstructor {
  new (form: Ref<Form>, ...args: any[]): FormHandler;
}

interface FormHandlerWithParams<
  T extends FormHandlerConstructor = FormHandlerConstructor,
  U = ConstructorParameters<T>[1]
> {
  class: T;
  options?: U;
}

export interface UseFormOptions {
  successKey?: string;
  failureKey?: string;
}

export interface UseFormResult {
  form: Ref<Form>;
  submitting: Ref<boolean>;
  submitForm: FormSubmitFn;
  resetForm: () => void;
}

export function useForm(handlersWithParams: FormHandlerWithParams[], options: UseFormOptions = {}): UseFormResult {
  if (handlersWithParams.length === 0) throw Error("Must pass at least one handler to useForm");

  const form = ref<Form>({});
  const handlers = handlersWithParams.map(h => new h.class(form, h.options));

  form.value = createEmptyForm(handlers);
  const submitting = ref(false);
  const { successKey, failureKey } = options;

  populateForm(handlers, true);
  afterFirstPopulate(handlers);

  return {
    form,
    submitting,

    async submitForm({ serverParams = { submitAll: false }, descriptor } = {}) {
      if (submitting.value) return;

      submitting.value = true;
      const handlerSubmitSelections = getHandlerSubmitSelections(handlers, descriptor);
      beforeSubmit(handlerSubmitSelections);

      const success = await submitForm(handlerSubmitSelections, serverParams);
      if (success) {
        store.commit("showSnackbar", { color: "success", key: successKey });
      } else {
        store.commit("showSnackbar", { color: "warning", key: failureKey });
      }

      afterSubmit(handlerSubmitSelections, success);
      submitting.value = false;
    },

    resetForm() {
      form.value = createEmptyForm(handlers);
      populateForm(handlers, true);
    }
  };
}

function createEmptyForm(handlers: FormHandler[]): Form {
  const handlerConfigs = handlers.map(h => h.getFormConfig());
  const config: Partial<FormConfig> = merge({}, ...handlerConfigs);
  if (!config.i18nNamespace || !config.fields) throw Error("Must provide complete form config");

  return {
    config
  };
}

function populateForm(handlers: FormHandler[], force = false): void {
  handlers.forEach(h => h.populateForm(force));
}

function afterFirstPopulate(handlers: FormHandler[]): void {
  handlers.forEach(h => h.afterFirstPopulate());
}

function getHandlerSubmitSelections(handlers: FormHandler[], descriptor?: FieldDescriptor): HandlerSubmitSelection[] {
  return handlers.map(handler => ({
    handler,
    submitSelection: getSubmitSelection(handler, descriptor)
  }));
}

function getSubmitSelection(handler: FormHandler, descriptor?: FieldDescriptor): SubmitSelection {
  const { form } = handler;
  const selection = handler.getSubmitSelection();
  if (descriptor) selection.descriptors = [descriptor];

  const filteredDescriptors = selection.descriptors.filter(descriptor => {
    const field = getFieldObject(form.value.config, form.value, descriptor);
    if (field.config.readonly) return false;
    if (isFieldDisabled(form.value, field)) return false;
    return true;
  });

  return {
    ...selection,
    descriptors: filteredDescriptors
  };
}

function beforeSubmit(handlerSubmitSelections: HandlerSubmitSelection[]): void {
  handlerSubmitSelections.forEach(s => s.handler.beforeSubmit(s.submitSelection));
}

async function submitForm(
  handlerSubmitSelections: HandlerSubmitSelection[],
  serverParams: FormSubmitServerParams
): Promise<boolean> {
  const allSuccess = await everyAsync(handlerSubmitSelections, async ({ handler, submitSelection }) => {
    const values = handler.getValuesToSubmit(submitSelection);
    const success = await handler.submit(values, submitSelection, serverParams);
    return success;
  });

  return allSuccess;
}

function afterSubmit(handlerSubmitSelections: HandlerSubmitSelection[], success: boolean): void {
  handlerSubmitSelections.forEach(s => s.handler.afterSubmit(success, s.submitSelection));
}

function isFieldDisabled(form: Form, field: Field): boolean {
  const {
    config: { disabled },
    descriptor: { params }
  } = field;
  return Boolean(typeof disabled === "function" ? disabled({ model: form, params }) : disabled);
}

async function everyAsync<T>(array: T[], callback: (el: T) => Promise<boolean>) {
  for (const element of array) {
    const result = await callback(element);
    if (!result) return false;
  }
  return true;
}
