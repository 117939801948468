import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { FormSubmitServerParams, GqlMutationResult } from "@/types";
import { ignoredProperties } from "@/gql/fragments/ignored-properties";

interface UpdateDeviceSettingsResult extends GqlMutationResult {
  ignoredProperties: string[];
  deviceLock: boolean;
}

export default function (
  assetUuid: string,
  settings: Record<string, any>,
  serverParams: FormSubmitServerParams
): Promise<UpdateDeviceSettingsResult> {
  return apolloClient
    .mutate({
      mutation: gql`
        ${ignoredProperties}
        mutation ($assetUuid: ID!, $settings: JSON!, $force: Boolean) {
          updateDeviceSettings(assetUuid: $assetUuid, settings: $settings, force: $force) {
            statusCode
            message
            stamp
            ...IgnoredPropertiesFields
          }
        }
      `,
      variables: {
        assetUuid,
        settings,
        force: serverParams.submitAll ?? false
      }
    })
    .then(response => {
      const result: UpdateDeviceSettingsResult | undefined = response.data.updateDeviceSettings;
      if (!result) throw Error("updateDeviceSettings mutation did not return a result");

      const { statusCode } = result;
      if (statusCode < 200 || statusCode >= 300)
        throw Error(`updateDeviceSettings failed with statusCode ${statusCode}`);

      return result;
    });
}
